<template>
  <div style="height:100vh">
    <iframe v-if="flag" width="100%" height="100%" scrolling="auto" :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      k: null,
      company_id: null,
      id: null,
      src: "/platform/#/supplierCrm/quaInfo",
      flag: true
    };
  },
  created() {


    
    this.k = this.$route.query.k;
    this.company_id = this.$route.query.company_id;
    //   this.id = this.$route.query.id
    //   let k,company_id,id
    if (this.k && this.company_id) {
      console.log()
      // this.flag = true
      if(process.env.NODE_ENV === 'development'){
           this.src = `http://localhost:17000/crm/large/supplierCrm/quaInfo?k=${
        this.k
      }&company_id=${this.company_id}`;
      console.log(this.src)

        // ------
      //   this.src = `http://localhost:8080/#/supplierCrm/quaInfo?k=${
      //   this.k
      // }&company_id=${this.company_id}`;
      }else{
        this.src = `https://${document.domain}/crm/large/supplierCrm/quaInfo?k=${
        this.k
      }&company_id=${this.company_id}`;
      }
      
    }
      console.log(this.src)
  },
  mounted() {
    
    let that = this
    window.addEventListener("message", function(event) {
      console.log("scrrrr");
      console.log(event);
      console.log(that.$router)
      if(event.data&&event.data.data&&event.data.data.id){
         that.$router.push({
        path: "/information/status",
        query: {
          id: event.data.data.id,
          serial_number:event.data.data.serial_number,
          created_at:event.data.data.created_at,
          k:that.$route.query.k,
          company_id:that.$route.query.company_id,
          classify_name:event.data.data.classify_name,
          code:event.data.data.code,
          types_name:event.data.data.types_name,
          process_id:event.data.data.process_id,
        }
      });
      }
     
    });
  },
  methods: {
    set() {
      console.log("11111shoudao");
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  // height: 100%;
}
</style>